import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Content5 from "../../sections/swaps/Content5";
import Content9 from "../../sections/swaps/Content9";
import CTA from "../../components/CTA/CTA";

const Swaps = () => {
  return (
    <>
      <PageWrapper>
        <Content5 className="pt-26 pt-lg-34 pb-10 pb-sm-5 pb-lg-14" />
        <Content9 className="pt-11 pt-lg-34 pb-13 pb-lg-28 pb-0 pb-lg-31" />
        <CTA className="bg-buttercup py-10" />
      </PageWrapper>
    </>
  );
};
export default Swaps;
