import React from "react";
import imgBanner from "../../assets/image/inner-page/png/swaps-banner.png";

const Content9 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* Section title */}
        <div className="row align-items-xl-center justify-content-between">
          <div
            className="col-xl-7 col-lg-6 col-md-10"
            data-aos="fade-right"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <h3 className="font-size-10 mb-0 letter-spacing-n83">
              Que no se descontrole tu planificación
            </h3>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-md-8"
            data-aos="fade-left"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <p className="font-size-7 mb-0 pr-xl-2 pr-lg-5 pr-md-10 pr-sm-20 mt-7 mt-lg-0">
              Si tus empleados/as no pueden realizar su turno o tarea, pueden
              solicitar cambiarla con o sin tu supervisión, tu decides.
            </p>
          </div>
        </div>
        {/* End Section title */}
        {/* Banner */}
        <div className="row">
          <div className="col-12">
            <div
              className="pt-10 pt-lg-17 rounded-10"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <img className="w-100" src={imgBanner} alt="" />
            </div>
          </div>
        </div>
        {/* End Banner */}
        {/* Services */}
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20"
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="square-60 bg-blue-3 shadow-dodger-blue-3 rounded-10 text-white font-size-7">
                <i className="fa fa-sun" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Diaria</h4>
                <p className="font-size-5 mb-0">
                  Realiza permutas de un día para intercambiar el turno o
                  descanso.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <div className="square-60 bg-dark-green shadow-dark-green rounded-10 text-white font-size-7">
                <i className="fa fa-calendar" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Semanal</h4>
                <p className="font-size-5 mb-0">
                  Intercambia la semana entera con sus turnos y descansos.
                  Timeview te indicará si los descansos y días de trabajo se
                  quedan compensados.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20"
              data-aos="fade-up"
              data-aos-delay={900}
              data-aos-once="true"
            >
              <div className="square-60 bg-buttercup shadow-buttercup rounded-10 text-white font-size-7">
                <i className="fa fa-umbrella-beach" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Vacaciones</h4>
                <p className="font-size-5 mb-0">
                  Si las vacaciones ya están asignadas y no puedes moverlas,
                  permite al empleado/a intercambiarlas.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Services */}
      </div>
    </div>
  );
};

export default Content9;
